<template>
  <section>
    <vue-good-table
      class="mb-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="isRtl"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
    
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <b-button
          v-if="props.column.field === 'action'"
          variant="outline-none"
          @click="sendEmail(props.row.id)"
        >
          <feather-icon
            icon="MailIcon"
            size="20"
          />
        </b-button>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
    </vue-good-table>
    <email-compose
      v-if="userId"
      v-model="shallShowEmailComposeModal"
      :user-id="userId"
      :is-program-communications="true"
    />
  </section>
</template>

<script>
import {
  BButton
} from "bootstrap-vue";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { userRolesDisplay, participantRolesDisplay, userRoles as userRolesModel } from '@models/userRoles';
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import usersService from "@/services/usersService";
import { mapGetters } from 'vuex';
import { makeErrorToast } from "@/libs/utils";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import EmailCompose from "@/participants/communications/EmailCompose.vue";
import { ref } from '@vue/composition-api';
import { roleAlias } from "@/@aom-core/utils/utils";


export default {
  components: {
    VueGoodTable,
    TablePagination,
    EmailCompose,
    BButton
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      bulkSelection: [],
      columns: [],
      rows: [],
      columnFilters: [],
      sort: [],
      userId: 0
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),

    isRtl() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.columns = [
      {
        label: "Full Name",
        field: "full_name",
        filterOptions: {
          enabled: true,
          placeholder: "Full Name",
        },
        width: "12em",
      },
      {
        label: "Role",
        field: "role",
        filterOptions: {
          enabled: true,
          placeholder: "All",
          filterDropdownItems: participantRolesDisplay.map(r => { return {...r, text: roleAlias(r.value, this.defaultProgram)};}),
          filterFn: this.participantStatus
        },
        width: "10em",
      },
      {
        field: 'action',
        sortable: false,
        width: "5em"
      }
    ];
    this.loadItems();
  },
  methods: {
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
    sendEmail(userId) {
      this.userId = userId;
      this.shallShowEmailComposeModal = true;
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "full_name",
        "role",
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'role'){
            let roleIds = [];
            roleIds.push(params.columnFilters.role);
            columnFilters.push({
              field: col,
              value: roleIds
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await usersService.getListParticipantsByProgram(this.defaultProgramId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total }  = response.data;
        this.total = total;
        const mappedItems = items.map(i => i = { 
          ...i,
          role: this.userRole(i?.user_roles)
        });
        this.rows = mappedItems;
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
    },
    userRole(userRoles) {
      if(userRoles.length > 0) {
        const roles = userRoles.map(r => userRolesDisplay[r.role.id]);
        const uniqueRoles = [...new Set(roles)];

        // remove PARTICIPANT CANDIDATE role if MENTOR/MENTEE role is existed
        const hasParticipantRoles = uniqueRoles.findIndex(r => r === userRolesDisplay[userRolesModel.MENTOR] || userRolesDisplay[userRolesModel.MENTEE]);
        const hasParticipantCandidateRole = uniqueRoles.findIndex(r => r === userRolesDisplay[userRolesModel.PARTICIPANT_CANDIDATE]);
        if (hasParticipantRoles !== -1 && hasParticipantCandidateRole !== -1) {
          uniqueRoles.splice(hasParticipantCandidateRole, 1);
        }
        return uniqueRoles.map(role => {
          const index = Object.values(userRolesDisplay).findIndex(label => label === role);
          if (index > -1) {
            return roleAlias(Object.keys(userRolesDisplay)[index], this.defaultProgram);
          }
          return role;
        }).join(", ");
      }
      return '';
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    const shallShowEmailComposeModal = ref(false);

    return {
      total,
      perPage,
      page,
      shallShowEmailComposeModal,
      roleAlias,
      userRolesModel
    };
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
@import "~@/assets/scss/base/pages/app-email.scss";
</style>